
import { Options, Vue } from "vue-class-component";
import StoreDryCleaner from "../../service/StoreDryCleaner";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class StoreDryCleaners extends Vue {
  private imagePath = "";
  private lists = [];
  private storeDryCleaner;
  private allDryCleanerList;
  private allBranch;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Assign Dry Cleaner" },
  ];

  private sectionObj = [
    { key: "section_1", value: "Section 1" },
    { key: "section_2", value: "Section 2" },
  ];

  private product = {
    id: 0,
    storeId: {},
    dryCleanerId: {},
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private selectedDryCleanerId = "";
  private selectedDryCleanerObj = {};

  private selectedBranchId = "";
  private selectedBranchObj = {};

  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.storeDryCleaner = new StoreDryCleaner();
    this.imagePath = this.storeDryCleaner.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  setDropDownData() {
    this.allBranch.filter((elem) => {
      if (elem.code == this.selectedBranchId) {
        this.selectedBranchObj = elem;
      }
    });

    this.allDryCleanerList.filter((elem) => {
      if (elem.dry_clearner_id == this.selectedDryCleanerId) {
        this.selectedDryCleanerObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      storeId: {},
      dryCleanerId: {},
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Assign Stores";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.storeId) {
      if (this.product.id != 0) {
        this.storeDryCleaner.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.storeDryCleaner.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        storeId: {},
        dryCleanerId: {},
        status: "",
      };
    }
  }

  // OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Store Dry Cleaner";
    this.productDialog = true;

    this.storeDryCleaner.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedBranchId = res[0].branch_id;
        this.selectedDryCleanerId = res[0].dry_clearner_id;
        this.setDropDownData();

        this.product = {
          id: res[0].assign_branch_cleaner_id,
          storeId: this.selectedBranchObj,
          dryCleanerId: this.selectedDryCleanerObj,
          status: res[0].branch_status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.assign_branch_cleaner_id,
      storeId: {},
      dryCleanerId: {},
      status: data.branch_status,
    };

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.storeDryCleaner.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.storeDryCleaner.getItems(page).then((data) => {
      this.lists = data.dry_cleaners;
      this.totalRecords = data.total_store_count;
      this.limit = data.limit_per_page;
      this.allDryCleanerList = data.dry_clearners_list;
      this.allBranch = data.branches;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.storeDryCleaner
        .getSearchedStoreDryCleaners(this.keyword)
        .then((data) => {
          this.lists = data.dry_cleaners;
          this.allDryCleanerList = data.dry_clearners_list;
          this.allBranch = data.branches;
          this.checkPagination = data.pagination;
        });
    }
  }
}
