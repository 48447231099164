
import { Options, Vue } from "vue-class-component";
import Service from "../../service/Service";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Services extends Vue {
  private imagePath = "";
  private lists = [];
  private services;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private loading = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Services" },
  ];
  private toast;

  private product = {
    id: 0,
    name: "",
    priority: 2,
    status: "",
    picture: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.services = new Service();
    this.toast = new Toaster();
    this.imagePath = this.services.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      priority: 2,
      status: "",
      picture: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Service";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.checkPagination = true;

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.services.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.services.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        priority: 2,
        status: "",
        picture: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Service";
    this.productDialog = true;
    this.checkPagination = true;

    this.services.getItem(data).then((res) => {
      if (res.length > 0) {
        this.product = {
          id: res[0].id,
          name: res[0].name,
          priority: res[0].priority,
          status: res[0].status,
          picture: res[0].picture,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.name,
      priority: data.priority,
      status: data.status,
      picture: data.picture,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.services.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.services.getItems(page).then((data) => {
      this.lists = data.service_list;
      this.totalRecords = data.total_service_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.services.getSearchedServices(this.keyword).then((data) => {
        this.lists = data.service_list;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }
}
