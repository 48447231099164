
import { Options, Vue } from "vue-class-component";
import StoreExpense from "../../service/StoreExpense";
import Toaster from "../../helpers/Toaster";
import { IStoreExpense } from "../storeexpenses/IStoreExpense";

@Options({
  components: {},
})
export default class StoreExpenses extends Vue {
  private lists = [];
  private storeexpense;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Store Expense" },
  ];
  private toast;

  private product: IStoreExpense = {
    id: "",
    associateID: "",
    expenseAmount: 2,
    expenseReason: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.storeexpense = new StoreExpense();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Add Store Expense";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.associateID != "") {
      if (this.product.id != "") {
        this.storeexpense.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.storeexpense.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Store Expense";
    this.productDialog = true;

    this.storeexpense.getItem(data).then((res) => {
      if (res) {
        this.product = {
          id: res.id,
          associateID: res.employee_id,
          expenseAmount: res.amount,
          expenseReason: res.reason,
        };
      }
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.storeexpense.getItems(page).then((data) => {
      this.lists = data.expense_list;
      this.totalRecords = data.total_expense_count;
      this.limit = data.limit_per_page;
    });
  }

  clearItem() {
    this.product = {
      id: "",
      associateID: "",
      expenseAmount: 0,
      expenseReason: "",
    };
  }

  //CLOSE THE ITEM DAILOG BOX
  closeDialogBox() {
    this.productDialog = false;
    this.submitted = false;
    this.clearItem();
  }
}
