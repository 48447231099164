
import { Options, Vue } from "vue-class-component";
import Surves from "../../service/Surves";
import Toaster from "../../helpers/Toaster";
import { ISurvey, branchDetail } from "../survey/ISurvey";

@Options({
  components: {},
})
export default class Survey extends Vue {
  private imagePath = "";
  private keyword = "";
  private checkPagination = true;
  private lists = [];
  private Surves;
  private allBranches: Array<branchDetail> = [];
  private productStatus = "";
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Survey" },
  ];
  private toast;

  private product: ISurvey = {
    id: "",
    question: "",
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
    correctOpt: {},
    destination: {},
    filterStores: [],
    status: "",
  };

  private allOptions = [
    { key: "option_1", value: "Option 1" },
    { key: "option_2", value: "Option 2" },
    { key: "option_3", value: "Option 3" },
    { key: "option_4", value: "Option 4" },
    { key: "option_5", value: "Option 5" },
  ];

  private destinations = [
    { key: "All Branches", value: "All Stores" },
    { key: "Specific Branches", value: "Specific Stores" },
  ];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.Surves = new Surves();
    this.toast = new Toaster();
    this.imagePath = this.Surves.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Create Survey Question";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.question.trim()) {
      if (this.product.id != "") {
        this.Surves.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.Surves.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Survey Question";
    this.productDialog = true;

    this.Surves.getItem(data).then((res) => {
      if (res.length > 0) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        const stores = this.storesFilter(res[0].branches);
        const allOptions = this.allOptionsFilter(res[0].answer);
        const destinations = this.destinationFilter(res[0].branch_type);

        this.product.id = res[0].survey_id;
        this.product.question = res[0].survey_question;
        this.product.opt1 = res[0].option_1;
        this.product.opt2 = res[0].option_2;
        this.product.opt3 = res[0].option_3;
        this.product.opt4 = res[0].option_4;
        this.product.opt5 = res[0].option_5;
        this.product.correctOpt = allOptions;
        this.product.destination = destinations;
        this.product.filterStores = stores;
        this.product.status = status;
      }
    });
  }

  //USED TO FILTER TYPES
  storesFilter(res) {
    const stores: Array<branchDetail> = [];
    this.allBranches.filter((elem) => {
      res.filter((storedElem) => {
        if (elem.code == storedElem) {
          stores.push({
            code: elem.code,
            mall: elem.mall,
          });
        }
      });
    });

    return stores;
  }

  //USED TO FILTER TYPES
  allOptionsFilter(res) {
    let options = {};

    this.allOptions.filter((elem) => {
      if (elem.key == res) {
        options = elem;
      }
    });
    return options;
  }

  //USED TO FILTER TYPES
  destinationFilter(res) {
    let dest = {};

    this.destinations.filter((elem) => {
      if (elem.key == res) {
        dest = elem;
      }
    });
    return dest;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product.id = data.survey_id;
    this.product.question = data.survey_question;
    this.product.status = data.status;
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.Surves.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.Surves.getItems(page).then((data) => {
      this.lists = data.survey_record;
      this.totalRecords = data.total_survey_count;
      this.limit = data.limit_per_page;
      this.allBranches = data.branches_list;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.Surves.getSearchedSurves(this.keyword).then((data) => {
        this.lists = data.survey_record;
        this.allBranches = data.branches_list;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearItem() {
    this.product.id = "";
    this.product.question = "";
    this.product.opt1 = "";
    this.product.opt2 = "";
    this.product.opt3 = "";
    this.product.opt4 = "";
    this.product.opt5 = "";
    this.product.correctOpt = {};
    this.product.destination = {};
    this.product.filterStores = [];
    this.product.status = "";
  }

  //CLOSE THE ITEM DAILOG BOX
  closeDialogBox() {
    this.productDialog = false;
    this.submitted = false;
    this.clearItem();
  }
}
